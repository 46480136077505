import React from "react";
import Button from "../../components/Button";
import Layout from "../../components/Layout";
import Section from "../../components/Section";
import { Link } from "gatsby";
import TitleBar from "../../components/TitleBar";
import Headline from "../../components/Headline";
import Ctas from "../../components/Ctas";
import SqueakSnippet from "../../components/SqueakSnippet";
import { QnaJs } from "../../components/Icons";
import 'react-medium-image-zoom/dist/styles.css'

const ToolkitQna = () => {
  return (
    <Layout title="Get help with Q&A.js" description="Q&A.js is an embeddable, open source widget for people to ask or answer questions. It's like Stack Overflow in your docs!">
      <TitleBar title="Q&amp;A.js" support icon={<QnaJs />}>
        <div className="hidden sm:flex">
          <Button url="https://squeak.cloud/signup">
              Try Squeak! Cloud
          </Button>
        </div>
      </TitleBar>
      
      <article className="">
        <Section id="overview" className="!mb-8">
          <Headline
            h1="Questions about Q&amp;A.js?"
            subtitle="Ask anything about installation, how it works, or anything related to Q&amp;A.js."
          />

          <Ctas className="pb-2">
              <Button
                url="https://github.com/posthog/squeak/wiki"
                type="primary"
              >
                Read the docs
              </Button>

              <Button
                url="/toolkit/qna.js"
                type="secondary"
              >
                Learn more about Q&amp;A.js
              </Button>
          </Ctas>

          <p className="text-base">
            Bug report or feature request?{" "}
            <Link
              href="https://github.com/posthog/squeak-react"
              target="_blank"
              rel="noreferrer"
              className=""
            >
              File an issue on GitHub
            </Link>
          </p>
        </Section>

        <div className="border-t border-dashed border-gray">
          <Section id="questions" >
            <h3>Ask a question</h3>
            <SqueakSnippet />
          </Section>
        </div>
      </article>
    </Layout>
  );
};

export default ToolkitQna;
